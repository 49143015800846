import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Layout from "components/layout"
import Markdown from "components/markdown"

const ContactPage = ({ data }) => {
  const {
    body: {
      childMarkdownRemark: { html },
    },
    title,
  } = data.contentfulPage

  return (
    <Layout>
      <SEO title="Contact Me" />
      <h1>{title}</h1>
      <Markdown html={html} />
    </Layout>
  )
}

export const contactPageQuery = graphql`
  query contactPageQuery {
    contentfulPage(contentful_id: { eq: "6Edn0DKmC3CCfRRtSIPXwP" }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`

export default ContactPage
